    // Header scroll class
    $(window).scroll(function () {
        if ($(this).scrollTop() > 50) {
            $('#header-static').addClass('header-scrolled');
    
        } else {
     
            $('#header-static').removeClass('header-scrolled');
        }
    });






 

